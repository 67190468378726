import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import IsLogin from "../../Common/IsLogin";
import { LoaderComponent } from "../../Common/LoaderComponent";
import Swal from "sweetalert2";
import Http from "../../Common/Http";

// https://getcssscan.com/css-box-shadow-examples

const StaffDashboardContent = (attendanceGiven) => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  const [receiveData, setReceiveData] = useState({});
  const [id, setId] = useState({});
  const [distance, setDistance] = useState('');
  const [loading, setLoading] = useState(false);
  const [attendanceGivenTime, setAttendanceGivenTime] = useState(new Date().toTimeString().slice(0, 5));
  const [showMovementSlip, setShowMovementSlip] = useState(false);
  const [movementType, setMovementType] = useState(''); // official or personal
  const [remarks, setRemarks] = useState('');
  const [time, setTime] = useState('');
  const [endtime, setEndtime] = useState('');
  const [lat, setLat] = useState('23.7644357');
  const [lng, setLng] = useState('90.358958');
  const [officeAttendanceEndTime, setOfficeAttendanceEndTime] = useState('10:15:00');
  const [distanceAllowed, setDistanceAllowed] = useState('10');
  const [attendanceInfo, setAttendanceInfo] = useState({in_time:'',out_time:''});
  // let modal;
  const signOut = () => {
    localStorage.clear();
    navigate("/login");
  };

  // useEffect(() => {
  //   Http.get("/attendance-status").then((res) => {
  //     setLat(res.data.setting.lat);
  //     setLng(res.data.setting.lng);
  //     setOfficeAttendanceEndTime(res.data.setting.last_time);
  //     setDistanceAllowed(res.data.setting.distance);
  //   }).catch((error) => {
  //     console.error('Error fetching data:', error);
  //   });
  //   if (showMovementSlip) {
  //     modal = new window.bootstrap.Modal(document.getElementById('movementSlipModal'));
  //     modal.show();
  //   }
  // }, [showMovementSlip]);

  useEffect(() => {
    Http.get("/attendance-status").then((res) => {
      setLat(res.data.setting.lat);
      setLng(res.data.setting.lng);
      setOfficeAttendanceEndTime(res.data.setting.last_time);
      setDistanceAllowed(res.data.setting.distance);
      setAttendanceInfo(res.data.attendance_info);
    }).catch((error) => {
      console.error('Error fetching data:', error);
    });

    // Modal Hide and ShowMovementSlip is false
    let modalElement = document.getElementById('movementSlipModal');
    let modalInstance = null;

    if (showMovementSlip) {
      modalInstance = new window.bootstrap.Modal(modalElement);
      modalInstance.show();

      // Add event listener for when the modal is hidden
      modalElement.addEventListener('hidden.bs.modal', () => {
        setShowMovementSlip(false);
      });
    }

    // Cleanup the event listener when the component is unmounted or modal is hidden
    return () => {
      if (modalElement) {
        modalElement.removeEventListener('hidden.bs.modal', () => {
          setShowMovementSlip(false);
        });
      }
    };
  }, [showMovementSlip]);
  

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Earth's radius in kilometers
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a = Math.sin(dLat / 2) ** 2 + 
            Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
            Math.sin(dLon / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in kilometers
  };

  // Function to obtain the current geolocation
  const getGeoLocation = () => {
      return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
          position => resolve({ lat: position.coords.latitude, lng: position.coords.longitude }),
          error => reject(error)
          );
      } else {
          reject(new Error("Geolocation is not supported by this browser."));
      }
      });
  };
  function timeStringToMillis(timeString) {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return hours * 3600000 + minutes * 60000 + seconds * 1000;
  }
  /*const formSubmit = async () => {
    try {
      const confirmAttendance = window.confirm("Are you sure want to give attendance?");
      if (!confirmAttendance) return;
      const location = await getGeoLocation();
      const distance = calculateDistance(location.lat, location.lng, lat, lng);

      // console.log(`Distance to fixed point is ${distance.toFixed(2)} kilometers.`);

      // Get current time and convert it to milliseconds since midnight
      const currentTime = new Date();
      const currentMillis = currentTime.getHours() * 3600000 + currentTime.getMinutes() * 60000 + currentTime.getSeconds() * 1000;

      // Assuming `last_time` is a string like '11:00:00'
      const lastTimeMillis = timeStringToMillis(officeAttendanceEndTime); // Convert '11:00:00' to milliseconds

      // console.log(distance,distanceAllowed,currentMillis,lastTimeMillis);
      if (distance > distanceAllowed || currentMillis > lastTimeMillis) {
        // if(distance > distanceAllowed) alert("Attendance successfully recorded! You are currently away from the office. Please complete the movement slip form to proceed.");
        // if(currentMillis > lastTimeMillis) alert("Attendance successfully recorded! You have exceeded the office-specified time. Please complete the movement slip form to proceed.");
        if(distance > distanceAllowed){
          // const confirmMsg = window.confirm("Attendance successfully recorded! You are currently away from the office. Please complete the movement slip form to proceed.");
          // if (!confirmMsg) return;
          Swal.fire({
            title: "Attendance successfully recorded! You are currently away from the office. Please complete the movement slip form to proceed.",
            icon: "warning",
            showCancelButton: false,
          }).then((result) => {
            console.log('success');
          });
        }
        if(currentMillis > lastTimeMillis){
          // const confirmMsg = window.confirm("Attendance successfully recorded! You have exceeded the office-specified time. Please complete the movement slip form to proceed.");
          // if (!confirmMsg) return;
          Swal.fire({
            title: "Attendance successfully recorded! You have exceeded the office-specified time. Please complete the movement slip form to proceed.",
            icon: "warning",
            showCancelButton: false
          }).then((result) => {
            console.log('success');
          });
        }

        setAttendanceGivenTime(new Date().toTimeString().slice(0, 5));
        setShowMovementSlip(true);
      } else {
        const is_movement_slip = false;
        const dataToSend = { ...inputs, ...location, distance, is_movement_slip};
        const response = await Http.post("/attendance-app-save", dataToSend);
        navigate("/attendance", {
          state: { message: response.data.message, type: "success" }
        });
      }
    } catch (error) {
      console.error("Error obtaining location or sending data:", error);
      setError(error.response ? error.response.data.errors : error);
    }
  };*/

  const formSubmit = async () => {
    try {
      const location = await getGeoLocation(); // Get the user's location
      const distance = calculateDistance(location.lat, location.lng, lat, lng); // Calculate distance from the office
  
      const confirmAttendance = await Swal.fire({
        title: "Are you sure you want to give attendance?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Take Attendance",
      });
  
      if (!confirmAttendance.isConfirmed) return; // Exit if the user cancels
  
      // Calculate current time in milliseconds since midnight
      const currentTime = new Date();
      const currentMillis = 
        currentTime.getHours() * 3600000 +
        currentTime.getMinutes() * 60000 +
        currentTime.getSeconds() * 1000;
  
      const lastTimeMillis = timeStringToMillis(officeAttendanceEndTime); // Convert attendance end time to milliseconds
  
      if (distance > distanceAllowed || currentMillis > lastTimeMillis) {
        // Handle distance and time validations
        if (distance > distanceAllowed) {
          await Swal.fire({
            title: "Attendance successfully recorded! You are currently away from the office. Please complete the movement slip form to proceed.",
            icon: "warning",
            showCancelButton: false,
          });
        }
  
        if (currentMillis > lastTimeMillis) {
          await Swal.fire({
            title: "Attendance successfully recorded! You have exceeded the office-specified time. Please complete the movement slip form to proceed.",
            icon: "warning",
            showCancelButton: false,
          });
        }
  
        setAttendanceGivenTime(new Date().toTimeString().slice(0, 5)); // Set the time for attendance
        setShowMovementSlip(true); // Show the movement slip form
      } else {
        // Proceed with normal attendance recording
        const is_movement_slip = false;
        const dataToSend = { ...inputs, ...location, distance, is_movement_slip };
  
        const response = await Http.post("/attendance-app-save", dataToSend);
        navigate("/attendance", {
          state: { message: response.data.message, type: "success" },
        });
      }
    } catch (error) {
      console.error("Error obtaining location or sending data:", error);
      setError(error.response ? error.response.data.errors : error);
    }
  };
  
  const handleMovementSlipSubmit = async () => {
    try {
      const location = await getGeoLocation();
      const is_movement_slip = true;
      const type = movementType;
      const now = new Date();
      const attendance_time = attendanceGivenTime;
      const entry_time = time;
      const end_time = endtime;
      const dataToSend = { type, remarks, attendance_time,entry_time,end_time, ...location, ...inputs, distance, is_movement_slip };
      
      const response = await Http.post("/attendance-app-save", dataToSend);
      
      // Assuming the response contains a `status` field to check for success

      // Hide the modal after form submission
      const modalElement = document.getElementById('movementSlipModal');
      const modalInstance = window.bootstrap.Modal.getInstance(modalElement); // Get existing modal instance

      if (modalInstance) {
        modalInstance.hide(); // Hide the modal
      }
      if (response.data.status === true) {
        navigate("/attendance", {
          state: { message: response.data.message, type: "success" }
        });
      } else {
        console.error("Submission failed:", response.data.message);
        setError(response.data.message);
      }
    } catch (error) {
      console.error("Error submitting movement slip:", error);
      setError(error.response ? error.response.data.errors : error);
    }
  };
  const checkoutFormSubmit = async () => {
    try {
      const confirmCheckout = window.confirm("Are you sure want to checkout?");
      if (!confirmCheckout) return;
      const location = await getGeoLocation();
      const is_movement_slip = false;
      const type = movementType;

      const currentTime = new Date();
      const hours = currentTime.getHours().toString().padStart(2, '0'); // Ensures 2 digits
      const minutes = currentTime.getMinutes().toString().padStart(2, '0'); // Ensures 2 digits
      const attendance_time = `${hours}:${minutes}`;

      // const attendance_time = new Date().toTimeString().slice(0, 5);
      const entry_time = time;
      const end_time = endtime;
      const dataToSend = { type, remarks, attendance_time,entry_time,end_time, ...location, ...inputs, distance, is_movement_slip };
      
      const response = await Http.post("/attendance-app-save", dataToSend);
      
      // Assuming the response contains a `status` field to check for success

      // Hide the modal after form submission
      const modalElement = document.getElementById('movementSlipModal');
      const modalInstance = window.bootstrap.Modal.getInstance(modalElement); // Get existing modal instance

      if (modalInstance) {
        modalInstance.hide(); // Hide the modal
      }
      if (response.data.status === true) {
        navigate("/attendance", {
          state: { message: response.data.message, type: "success" }
        });
      } else {
        console.error("Submission failed:", response.data.message);
        setError(response.data.message);
      }
    } catch (error) {
      console.error("Error submitting movement slip:", error);
      setError(error.response ? error.response.data.errors : error);
    }
  };
  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div className="row">
        <div className="col-md-6">
          <div style={{ padding:"25px 10px" }} className="card">
            {(attendanceGiven.attendanceGiven == null?<>
              <span style={{ cursor:"pointer" }} onClick={formSubmit} className="nav-link staff-dashboard-link staff-dashboard-link-box-shadow1">
                <i className="fa fa-clone" aria-hidden="true"></i>
                &nbsp;&nbsp; Give Attendance
                <hr style={{ backgroundColor:"green" }} />
              </span>
            </>:<>
            {/* <Link to="/attendance" className="nav-link staff-dashboard-link staff-dashboard-link-box-shadow1">
                <i className="fa fa-clone" aria-hidden="true"></i>
                &nbsp;&nbsp; Checkout
            </Link> */}
            <span style={{ cursor:"pointer" }} onClick={checkoutFormSubmit} className="nav-link staff-dashboard-link staff-dashboard-link-box-shadow1">
                <i className="fa fa-clone" aria-hidden="true"></i>
                {/* &nbsp;&nbsp; Checkout ({attendanceInfo.in_time + " - " + attendanceInfo.out_time}) */}
                &nbsp;&nbsp; Checkout
                <hr style={{ backgroundColor:"green" }} />
              </span>
            </>)}
          </div>
        </div>
        <div className="col-md-6">
          <div style={{ padding:"25px 10px" }} className="card">
            <Link to="/leave-application" className="nav-link staff-dashboard-link staff-dashboard-link-box-shadow1">
                <i className="fa fa-clone" aria-hidden="true"></i>
                &nbsp;&nbsp; Leave Applicationn
                <hr style={{ backgroundColor:"green" }} />
            </Link>
          </div>
        </div>
        <div className="col-md-6">
          <div style={{ padding:"25px 10px" }} className="card">
            <Link to="/movement-slip" className="nav-link staff-dashboard-link staff-dashboard-link-box-shadow1">
                <i className="fa fa-clone" aria-hidden="true"></i>
                &nbsp;&nbsp; Movement Slip
                <hr style={{ backgroundColor:"green" }} />
            </Link>
          </div>
        </div>
        <div className="col-md-6">
          <div style={{ padding:"25px 10px" }} className="card">
            <Link to="/billsubmission" className="nav-link staff-dashboard-link staff-dashboard-link-box-shadow1">
                <i className="fa fa-clone" aria-hidden="true"></i>
                &nbsp;&nbsp; Bill Submission
                <hr style={{ backgroundColor:"green" }} />
            </Link>
          </div>
        </div>
        <div className="col-md-6">
          <div style={{ padding:"25px 10px" }} className="card">
            <Link to="/reports" className="nav-link staff-dashboard-link staff-dashboard-link-box-shadow1">
                <i className="fa fa-clone" aria-hidden="true"></i>
                &nbsp;&nbsp; Reports
                <hr style={{ backgroundColor:"green" }} />
            </Link>
          </div>
        </div>
        <div className="col-md-6">
          <div style={{ padding:"25px 10px" }} className="card">
            <label style={{ cursor: "pointer",padding:"0px 13px" }} onClick={signOut} className="nav-link staff-dashboard-link staff-dashboard-link-box-shadow1">
              <i className="icon ion-android-exit exitIcon tx-15" aria-hidden="true"></i>
                &nbsp;&nbsp; Logout
                <hr style={{ backgroundColor:"green" }} />
            </label>
          </div>
        </div>
      </div>

        {showMovementSlip && (
          <div className="movement-slip p-5">
              <div className="modal fade" id="movementSlipModal" tabIndex="-1" aria-labelledby="movementSlipModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="movementSlipModalLabel">Movement Slip</h5>
                      <button type="button" className="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                      <div className="form-group">
                        <label>Type of Movement:</label>
                        <select className="form-control" required value={movementType} onChange={e => setMovementType(e.target.value)}>
                          <option value="">Select</option>
                          <option value="official">Official</option>
                          <option value="personal">Personal</option>
                        </select>
                      </div>
                      <div className="form-group mt-4">
                        <label>Start Time:</label>
                        <input type="time" className="form-control" required value={time} onChange={e => setTime(e.target.value)} />
                      </div>
                      <div className="form-group mt-4">
                        <label>Expected Time to Reach Office:</label>
                        <input type="time" className="form-control" required value={endtime} onChange={e => setEndtime(e.target.value)} />
                      </div>
                      <div className="form-group mt-4">
                        <label>Remarks:</label>
                        <textarea className="form-control" value={remarks} required onChange={e => setRemarks(e.target.value)} />
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary"  data-bs-dismiss="modal">Close</button>
                      <button type="button" className="btn btn-success" onClick={handleMovementSlipSubmit}>Submit Movement Slip</button>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        )}
    </div>
  );
};

export default StaffDashboardContent;
